import React from "react"
import { Layout, ILayoutProps } from "../components/Layout"
import { SEO } from "../components/Seo"
import { Link } from "gatsby";

export default class PageNotFound extends React.Component<ILayoutProps, {}>
{
    constructor(props: ILayoutProps)
    {
        super(props);
    }

    public render(): JSX.Element
    {
        return (
            <Layout className="404-page">
                <SEO title="404" image="" />

                <section className="hero">
                    <div className="hero-content">
                        <div className="dimsum-feature-2"></div>
                        <div className="content content-right">
                            <h1>Page 404 <br />not found</h1>
                            <p className="lead">
                                The page you landed on couldn't be found.
							</p>
                            <div className="button-group">
                                <Link to="/" title="Book a table" className="btn btn-outline btn-white">
                                    Go to the Homepage
								</Link>
                                <a className="btn btn-transparent btn-white" href="tel:01604 602818" title="Phone us on 01604 602818">
                                    Or phone +44(0) 1604 602818
								</a>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}